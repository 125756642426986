<template>
  <main class="page-points page-edit-point p-0 d-flex flex-column">
    <ul class="nav nav-pills nav-fill">
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.show' }">Dati</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link active" :to="{ name: 'points.edit' }" aria-current="page">Modifica</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.pick' }">Punto</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :class="{ 'disabled': !point.hasContours }" :to="{ name: 'points.contours' }">Contorni</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" :to="{ name: 'points.notes' }">Contenuti</router-link>
      </li>
    </ul>
    <div class="flex-grow-1 container pt-3">
      <PointForm :feedback.sync="feedback" :point="pointToEdit" @submitForm="submit" edit />
    </div>
  </main>
</template>

<script>

export default {
  name: 'point-edit',
  components: {
    PointForm: () => import('@/views/components/Form/PointForm'),
  },
  props: {
    project: {
      type: Object,
      required: true,
    },
    module: {
      type: Object,
      required: true,
    },
    point: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      pointToEdit: {},
      feedback: null,
    };
  },
  methods: {
    updatePointToEdit (point) {
      this.pointToEdit = {
        ...point,
        projectId: point?.project?.id || null,
        ...this.pointToEdit,
      };
    },
    submit (pointToEdit) {
      const point = { ...pointToEdit };

      this.$api.updatePoint(point.id, point)
        .then(res => {
          this.feedback = true;
          this.$emit('update:point', res.data);
          this.pointToEdit = {};
          this.$router.push({ name: 'points.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updatePointToEdit(this.point);
  },
  watch: {
    point (newValue) {
      this.updatePointToEdit(newValue);
    },
  },
};

</script>
